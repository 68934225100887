import { useState, useEffect, useCallback } from 'react';
import { useApi } from './useApi';
import type {
  AccessActivity,
  AuditLog,
  RoleChange,
  CompromisedAccount,
  PrivilegedAccount,
  SecurityIncident,
  SecurityAlert,
  SecureScore
} from './index';

export const useIdentityMonitoring = () => {
    const { getIdentityData } = useApi();
    const [refreshKey, setRefreshKey] = useState(0);
    const [data, setData] = useState<{
      accessActivity: any[];
      roleChanges: any[];
      compromisedAccounts: any[];
      privilegedAccounts: any[];
    } | null>(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<string | null>(null);
  
    useEffect(() => {
      const fetchData = async () => {
        try {
          setLoading(true);
          const result = await getIdentityData();
          setData(result);
          setError(null);
        } catch (err: any) {
          setError(err.message);
        } finally {
          setLoading(false);
        }
      };
  
      fetchData();
    }, [refreshKey, getIdentityData]);
  
    const refresh = useCallback(() => {
      setRefreshKey(prev => prev + 1);
    }, []);
  
    return {
      accessActivity: data?.accessActivity ?? [],
      roleChanges: data?.roleChanges ?? [],
      compromisedAccounts: data?.compromisedAccounts ?? [],
      privilegedAccounts: data?.privilegedAccounts ?? [],
      loading,
      error,
      refresh
    };
  };