import React, { useState, useCallback, useEffect } from 'react';
import { SecurityAlert} from './index';
import {
  Alert,
  Box,
  Card,
  CardContent,
  Grid,
  Stack,
  Typography,
  List,
  ListItem,
  ListItemText,
  Tabs,
  Tab,
  Chip,
  Button,
  ListItemIcon,
  LinearProgress,
  Dialog,
  DialogTitle,
  DialogContent,
} from '@mui/material';
import {
  Security,
  Warning,
  Person,
  Storage,
  CloudQueue,
  Shield,
} from '@mui/icons-material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { useSecurityIncidents } from './useAzureLogs';

const REFRESH_INTERVAL = 300000; // 5 minutes

const scoreColumns: GridColDef[] = [
  { field: 'control', headerName: 'Security Control', flex: 1 },
  { 
    field: 'score', 
    headerName: 'Score', 
    width: 130,
    valueFormatter: (params) => `${params.value}%`
  },
  { 
    field: 'maxScore', 
    headerName: 'Max Score', 
    width: 130,
    valueFormatter: (params) => `${params.value}%`
  },
  { 
    field: 'status', 
    headerName: 'Status', 
    width: 150,
    renderCell: (params) => (
      <Chip
        label={params.value}
        color={params.value === 'Healthy' ? 'success' : 'error'}
        size="small"
      />
    )
  },
];

interface AlertDialogProps {
  alert: SecurityAlert | null;
  onClose: () => void;
}

const AlertDialog: React.FC<AlertDialogProps> = ({ alert, onClose }) => {
  if (!alert) return null;
  
  return (
    <Dialog open={Boolean(alert)} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>Alert Details</DialogTitle>
      <DialogContent>
        <Stack spacing={2}>
          <Typography variant="h6">{alert.title}</Typography>
          <Typography>{alert.description}</Typography>
          <Typography variant="caption">
            {new Date(alert.timestamp).toLocaleString()}
          </Typography>
        </Stack>
      </DialogContent>
    </Dialog>
  );
};

interface TabPanelProps {
  children?: React.ReactNode;
  value: number;
  index: number;
}

const TabPanel: React.FC<TabPanelProps> = ({ children, value, index }) => (
  <div
    role="tabpanel"
    hidden={value !== index}
    id={`security-tabpanel-${index}`}
    aria-labelledby={`security-tab-${index}`}
  >
    {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
  </div>
);

export default function SecurityDashboard() {
  const [tabValue, setTabValue] = useState(0);
  const [selectedAlert, setSelectedAlert] = useState<SecurityAlert | null>(null);
  const [lastRefresh, setLastRefresh] = useState<Date>(new Date());
  const [refreshError, setRefreshError] = useState<string | null>(null);

  const { 
    incidents, 
    securityScores,
    compromisedAccounts,
    securityAlerts,
    loading,
    error,
    refresh 
  } = useSecurityIncidents();

  const handleRefresh = useCallback(async () => {
    try {
      await refresh();
      setLastRefresh(new Date());
      setRefreshError(null);
    } catch (err) {
      setRefreshError(err instanceof Error ? err.message : 'Failed to refresh data');
    }
  }, [refresh]);

  useEffect(() => {
    const intervalId = setInterval(handleRefresh, REFRESH_INTERVAL);
    return () => clearInterval(intervalId);
  }, [handleRefresh]);

  const handleAlertClick = useCallback((alert: SecurityAlert) => {
    setSelectedAlert(alert);
  }, []);

  const handleCloseAlert = useCallback(() => {
    setSelectedAlert(null);
  }, []);

  if (error) {
    return (
      <Alert 
        severity="error" 
        action={
          <Button color="inherit" onClick={handleRefresh}>
            Retry
          </Button>
        }
      >
        {error}
      </Alert>
    );
  }

  return (
    <Box className="p-4">
      <Stack spacing={3}>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="h4" component="h1">
            Security Dashboard
          </Typography>
          <Typography variant="caption" color="text.secondary">
            Last updated: {lastRefresh.toLocaleString()}
          </Typography>
        </Box>

        {loading && <LinearProgress />}
        {refreshError && (
          <Alert severity="warning" onClose={() => setRefreshError(null)}>
            {refreshError}
          </Alert>
        )}

        <Tabs 
          value={tabValue} 
          onChange={(_, newValue) => setTabValue(newValue)} 
          aria-label="Security Dashboard Tabs"
        >
          <Tab icon={<Shield />} label="Overview" id="security-tab-0" />
          <Tab icon={<Person />} label="User Security" id="security-tab-1" />
          <Tab icon={<Storage />} label="Resource Security" id="security-tab-2" />
          <Tab icon={<CloudQueue />} label="Cloud Security" id="security-tab-3" />
        </Tabs>

        <TabPanel value={tabValue} index={0}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={8}>
              <Card>
                <CardContent>
                  <Typography variant="h6" gutterBottom>
                    Security Score Overview
                  </Typography>
                  <Box sx={{ height: 400, width: '100%' }}>
                    <DataGrid
                      rows={securityScores}
                      columns={scoreColumns}
                      initialState={{
                        pagination: { paginationModel: { pageSize: 5 } },
                      }}
                      pageSizeOptions={[5, 10, 25]}
                      loading={loading}
                      disableRowSelectionOnClick
                    />
                  </Box>
                </CardContent>
              </Card>
            </Grid>

            <Grid item xs={12} md={4}>
              <Card>
                <CardContent>
                  <Typography variant="h6" gutterBottom>
                    Active Alerts
                  </Typography>
                  <List>
                    {securityAlerts.map((alert) => (
                      <ListItem
                        key={alert.id}
                        onClick={() => handleAlertClick(alert)}
                      >
                        <ListItemIcon>
                          <Warning 
                            color={alert.severity === 'high' ? 'error' : 'warning'} 
                          />
                        </ListItemIcon>
                        <ListItemText
                          primary={alert.title}
                          secondary={new Date(alert.timestamp).toLocaleString()}
                        />
                      </ListItem>
                    ))}
                  </List>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </TabPanel>

        <AlertDialog alert={selectedAlert} onClose={handleCloseAlert} />
      </Stack>
    </Box>
  );
}