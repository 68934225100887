import React, { useState, useMemo } from 'react';
import {
  Alert,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  LinearProgress,
  Stack,
  Typography
} from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
//import { ThreatMap } from './ThreatMap';
//import { IncidentsList } from './components/IncidentsList';
import type { SecurityIncident, IncidentsListProps } from './index';
//import {  useSecurityIncidents } from './useSecurityIncidents';
import { useIdentityMonitoring } from './useIdentityMonitoring';
import { useSecurityIncidents } from './useSecurityIncidents';


const GRID_PAGE_SIZE = 25;

export default function IdentityMonitoring() {
  const [onIncidentSelect, setSelectedIncident] = useState<SecurityIncident | null>(null);
  
  const { 
    accessActivity,
    roleChanges,
    loading,
    error,
    refresh 
  } = useIdentityMonitoring();

  const columns = useMemo(() => ({
    accessActivity: [
      { 
        field: 'timestamp',
        headerName: 'Time',
        width: 180,
        valueFormatter: (params) => new Date(params.value).toLocaleString()
      },
      { field: 'userPrincipalName', headerName: 'User', flex: 1 },
      { field: 'activity', headerName: 'Activity', width: 200 },
      { field: 'resource', headerName: 'Resource', width: 200 },
      { field: 'ipAddress', headerName: 'IP Address', width: 150 },
      { field: 'location', headerName: 'Location', width: 150 },
      { 
        field: 'riskLevel',
        headerName: 'Risk Level',
        width: 130,
        renderCell: params => (
          <RiskLevelChip level={params.value} />
        )
      }
    ] as GridColDef[],
    
    roleChanges: [
      {
        field: 'timestamp',
        headerName: 'Time',
        width: 180,
        valueFormatter: (params) => new Date(params.value).toLocaleString()
      },
      { field: 'user', headerName: 'User', width: 200 },
      { field: 'oldRole', headerName: 'Previous Role', width: 200 },
      { field: 'newRole', headerName: 'New Role', flex: 1 }
    ] as GridColDef[]
  }), []);

  if (error) {
    return (
      <Alert 
        severity="error"
        action={
          <Button color="inherit" onClick={refresh}>Retry</Button>
        }
      >
        {error}
      </Alert>
    );
  }

  return (
    <Grid container spacing={3}>
      {loading && (
        <Grid item xs={12}>
          <LinearProgress />
        </Grid>
      )}

      <Grid item xs={12}>
        <MonitoringDashboard
          accessActivity={accessActivity}
          roleChanges={roleChanges}
          loading={loading}
          columns={columns}
          pageSize={GRID_PAGE_SIZE}
        />
      </Grid>

     
    </Grid>
  );
}

// Subcomponents
const RiskLevelChip: React.FC<{ level: string }> = React.memo(({ level }) => {
  const color = {
    high: 'error',
    medium: 'warning',
    low: 'success'
  }[level.toLowerCase()] || 'default';

  return (
    <Chip 
      label={level}
      color={color as 'error' | 'warning' | 'success' | 'default'}
      size="small"
    />
  );
});

// MonitoringDashboard.tsx
interface MonitoringDashboardProps {
  accessActivity: Array<any>;
  roleChanges: Array<any>;
  loading: boolean;
  columns: {
    accessActivity: GridColDef[];
    roleChanges: GridColDef[];
  };
  pageSize: number;
}

export const MonitoringDashboard: React.FC<MonitoringDashboardProps> = React.memo(({
  accessActivity,
  roleChanges,
  loading,
  columns,
  pageSize
}) => (
  <>
    <Card>
      <CardHeader title="Access Activity Monitor" />
      <CardContent>
        <DataGrid
          rows={accessActivity}
          columns={columns.accessActivity}
          loading={loading}
          initialState={{
            pagination: { paginationModel: { pageSize } }
          }}
          pageSizeOptions={[pageSize, pageSize * 2]}
          disableRowSelectionOnClick
          density="compact"
        />
      </CardContent>
    </Card>

    <Card sx={{ mt: 3 }}>
      <CardHeader title="Role Changes Monitor" />
      <CardContent>
        <DataGrid
          rows={roleChanges}
          columns={columns.roleChanges}
          loading={loading}
          initialState={{
            pagination: { paginationModel: { pageSize } }
          }}
          pageSizeOptions={[pageSize, pageSize * 2]}
          disableRowSelectionOnClick
          density="compact"
        />
      </CardContent>
    </Card>
  </>
));

// ThreatMap.tsx

// IncidentsList.tsx

export const IncidentsList: React.FC<IncidentsListProps> = React.memo(({
  incidents,
  selectedIncident,
  onIncidentSelect,
  loading
}) => (
  <Card>
    <CardHeader title="Security Incidents" />
    <CardContent>
      {loading ? (
        <LinearProgress />
      ) : (
        <Grid container spacing={2}>
          {incidents.map((incident) => (
            <Grid item xs={12} key={incident.id}>
              <Button
                variant="outlined"
                fullWidth
                onClick={() => onIncidentSelect(incident)} // Set the selected incident when button is clicked
              >
                {incident.title} {/* Display the title of the incident */}
              </Button>
            </Grid>
          ))}
        </Grid>
      )}
    </CardContent>
    {selectedIncident && (
      <Dialog open={Boolean(selectedIncident)} onClose={() => onIncidentSelect(null)}>
        <DialogTitle>Incident Details</DialogTitle>
        <DialogContent>
          <Typography variant="body1">
            {/* Display detailed information about the selected incident */}
            {selectedIncident.description}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => onIncidentSelect(null)}>Close</Button> {/* Close the dialog */}
        </DialogActions>
      </Dialog>
    )}
  </Card>
));


