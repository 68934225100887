import { BackendAuthService } from './backend-auth';
import { ServiceAuthConfig } from '../index';


export class BackendApiClient {
    private baseUrl: string;
    private authService: BackendAuthService;
    private serviceConfig: ServiceAuthConfig;
  
    constructor(
      baseUrl: string,
      authService: BackendAuthService,
      serviceConfig: ServiceAuthConfig
    ) {
      this.baseUrl = baseUrl;
      this.authService = authService;
      this.serviceConfig = serviceConfig;
    }
  
    private async getHeaders(): Promise<Headers> {
      const token = await this.authService.getServiceToken(this.serviceConfig);
      return new Headers({
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
      });
    }
  
    async fetchWithAuth(
      endpoint: string,
      options: RequestInit = {}
    ): Promise<Response> {
      const headers = await this.getHeaders();
      const response = await fetch(`${this.baseUrl}${endpoint}`, {
        ...options,
        headers: new Headers({
          ...Object.fromEntries(headers),
          ...Object.fromEntries(new Headers(options.headers || {})),
        }),
      });
  
      if (!response.ok) {
        throw new Error(`API error: ${response.statusText}`);
      }
  
      return response;
    }
  }