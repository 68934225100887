import { SignJWT, jwtVerify } from 'jose';
import { ServiceAuthConfig, BackendTokenPayload } from '../index';




export class BackendAuthService {
  private readonly SECRET_KEY: Uint8Array;
  private tokenCache: Map<string, { token: string; expires: number }>;

  constructor(secretKey: string) {
    this.SECRET_KEY = new TextEncoder().encode(secretKey);
    this.tokenCache = new Map();
  }

  async getServiceToken(config: ServiceAuthConfig): Promise<string> {
    const cacheKey = `${config.serviceId}-${config.scopes.join(',')}`;
    const cached = this.tokenCache.get(cacheKey);
    
    if (cached && cached.expires > Date.now()) {
      return cached.token;
    }

    const token = await this.generateServiceToken(config);
    const expires = Date.now() + 3600000; // 1 hour
    this.tokenCache.set(cacheKey, { token, expires });
    
    return token;
  }

  private async generateServiceToken(config: ServiceAuthConfig): Promise<string> {
    const token = await new SignJWT({
      serviceId: config.serviceId,
      scopes: config.scopes
    })
      .setProtectedHeader({ alg: 'HS256' })
      .setIssuedAt()
      .setExpirationTime('1h')
      .sign(this.SECRET_KEY);

    return token;
  }

  async validateServiceToken(token: string): Promise<BackendTokenPayload> {
    try {
      const { payload } = await jwtVerify(token, this.SECRET_KEY);
      return payload as BackendTokenPayload;
    } catch (error) {
      throw new Error('Invalid service token');
    }
  }
}