import { useState, useEffect, useCallback } from 'react';
import { useApi } from './useApi';

import type { 
  AccessActivity, 
  AuditLog, 
  SecurityIncident, 
  SecurityAlert, 
  SecureScore,
  CompromisedAccount,
  RoleChange,
  PrivilegedAccount 
} from './index';

export const useAzureLogs = () => {
  const [data, setData] = useState<{
    signInLogs: AuditLog[];
    auditLogs: AuditLog[];
  }>({ signInLogs: [], auditLogs: [] });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const api = useApi();

  const getLogs = useCallback(async (startDate: Date, endDate: Date) => {
    setLoading(true);
    try {
      const result = await api.getLogs(startDate, endDate);
      setData(result);
      setError(null);
    } catch (err) {
      setError(err instanceof Error ? err.message : 'Failed to fetch logs');
    } finally {
      setLoading(false);
    }
  }, [api]);

  return {
    signInLogs: data.signInLogs,
    auditLogs: data.auditLogs,
    loading,
    error,
    getLogs
  };
};

export const useSecurityIncidents = () => {
  const [data, setData] = useState<{
    incidents: SecurityIncident[];
    securityAlerts: SecurityAlert[];
    securityScores: SecureScore[];
    compromisedAccounts: CompromisedAccount[];
  }>({
    incidents: [],
    securityAlerts: [],
    securityScores: [],
    compromisedAccounts: []
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const api = useApi();

  const refresh = useCallback(async () => {
    setLoading(true);
    try {
      const result = await api.getSecurityIncidents();
      setData(result);
      setError(null);
    } catch (err) {
      setError(err instanceof Error ? err.message : 'Failed to fetch security data');
      throw err;
    } finally {
      setLoading(false);
    }
  }, [api]);

  useEffect(() => {
    refresh();
  }, [refresh]);

  return {
    incidents: data.incidents,
    securityAlerts: data.securityAlerts,
    securityScores: data.securityScores,
    compromisedAccounts: data.compromisedAccounts,
    loading,
    error,
    refresh
  };
};

export const useIdentityMonitoring = () => {
  const [data, setData] = useState<{
    accessActivity: AccessActivity[];
    roleChanges: RoleChange[];
    compromisedAccounts: CompromisedAccount[];
    privilegedAccounts: PrivilegedAccount[];
  }>({
    accessActivity: [],
    roleChanges: [],
    compromisedAccounts: [],
    privilegedAccounts: []
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const api = useApi();

  const refresh = useCallback(async () => {
    setLoading(true);
    try {
      const result = await api.getIdentityData();
      setData(result);
      setError(null);
    } catch (err) {
      setError(err instanceof Error ? err.message : 'Failed to fetch identity data');
    } finally {
      setLoading(false);
    }
  }, [api]);

  useEffect(() => {
    refresh();
  }, [refresh]);

  return {
    accessActivity: data.accessActivity,
    roleChanges: data.roleChanges,
    compromisedAccounts: data.compromisedAccounts,
    privilegedAccounts: data.privilegedAccounts,
    loading,
    error,
    refresh
  };
};