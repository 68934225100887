import React, { ReactNode } from 'react';
import { Routes, Route, Link, Navigate } from 'react-router-dom';
import { Box, AppBar, Toolbar, Button, Typography } from '@mui/material';
import { Security, Assessment, Timeline, Person } from '@mui/icons-material';
import { useIsAuthenticated } from '@azure/msal-react';
import SecurityDashboard from './SecurityDashboard';
import AzureLogsDashboard from './AzureLogsDashboard';
import SecurityIncidents from './SecurityIncidents';
import IdentityMonitoring from './IdentityMonitoring';
import HomePage from './HomePage';

interface PrivateRouteProps {
  children: ReactNode;
}

interface RouteConfig {
  path: string;
  label: string;
  icon: ReactNode;
}

function PrivateRoute({ children }: PrivateRouteProps) {
  const isAuthenticated = useIsAuthenticated();
  return isAuthenticated ? children : <Navigate to="/" />;
}

function Navigation() {
  const isAuthenticated = useIsAuthenticated();

  if (!isAuthenticated) return null;

  const routes: RouteConfig[] = [
    { path: '/security', label: 'Security', icon: <Security /> },
    { path: '/logs', label: 'Logs', icon: <Assessment /> },
    { path: '/incidents', label: 'Incidents', icon: <Timeline /> },
    { path: '/identity', label: 'Identity', icon: <Person /> },
  ];

  return (
    <AppBar position="static">
      <Toolbar>
        <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
          Azure Security Center
        </Typography>
        {routes.map(({ path, label, icon }) => (
          <Button
            key={path}
            component={Link}
            to={path}
            color="inherit"
            startIcon={icon}
            aria-label={`Navigate to ${label}`}
          >
            {label}
          </Button>
        ))}
      </Toolbar>
    </AppBar>
  );
}

export default function AppRouter() {
  return (
    <Box>
      <Navigation />
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route
          path="/security"
          element={
            <PrivateRoute>
              <SecurityDashboard />
            </PrivateRoute>
          }
        />
        <Route
          path="/logs"
          element={
            <PrivateRoute>
              <AzureLogsDashboard />
            </PrivateRoute>
          }
        />
        <Route
          path="/incidents"
          element={
            <PrivateRoute>
              <SecurityIncidents />
            </PrivateRoute>
          }
        />
        <Route
          path="/identity"
          element={
            <PrivateRoute>
              <IdentityMonitoring />
            </PrivateRoute>
          }
        />
        <Route path="*" element={<Typography variant="h5">404 - Page Not Found</Typography>} />
      </Routes>
    </Box>
  );
}