import React, { useState, useEffect } from 'react';
import { useAzureLogs } from './useAzureLogs';
import { 
  Alert, Card, CardContent, CardHeader, Grid, Stack, Box 
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { LoadingButton } from '@mui/lab';
import { Download as DownloadIcon, Refresh as RefreshIcon } from '@mui/icons-material';

const signInColumns: GridColDef[] = [
  { field: 'userPrincipalName', headerName: 'User', flex: 1 },
  { field: 'activity', headerName: 'Application', flex: 1 },
  { field: 'ipAddress', headerName: 'IP Address', width: 130 },
  { 
    field: 'timestamp', 
    headerName: 'Time', 
    width: 180,
    valueFormatter: (params) => new Date(params.value).toLocaleString()
  },
  { field: 'resource', headerName: 'Status', width: 130 },
  { field: 'riskLevel', headerName: 'Risk Level', width: 130 }
];

const auditColumns: GridColDef[] = [
  { field: 'action', headerName: 'Activity', flex: 1 },
  { 
    field: 'timestamp', 
    headerName: 'Time', 
    width: 180,
    valueFormatter: (params) => new Date(params.value).toLocaleString()
  },
  { field: 'user', headerName: 'User', width: 200 },
  { field: 'details', headerName: 'Result', flex: 1 }
];

export default function AzureLogsDashboard() {
  const [startDate, setStartDate] = useState<Date | null>(
    new Date(Date.now() - 7 * 24 * 60 * 60 * 1000)
  );
  const [endDate, setEndDate] = useState<Date | null>(new Date());
  
  const { signInLogs, auditLogs, loading, error, getLogs } = useAzureLogs();

  const handleRefresh = () => {
    if (startDate && endDate) {
      getLogs(startDate, endDate);
    }
  };

  useEffect(() => {
    handleRefresh();
  }, []);

  const handleDownload = (type: 'signin' | 'audit') => {
    const data = type === 'signin' ? signInLogs : auditLogs;
    const filename = `azure_${type}_logs_${startDate?.toISOString().split('T')[0]}_${endDate?.toISOString().split('T')[0]}.csv`;
    
    const headers = Object.keys(data[0] || {}).join(',');
    const rows = data.map(log => Object.values(log).join(',')).join('\n');
    const csvContent = `${headers}\n${rows}`;
    
    const blob = new Blob([csvContent], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = filename;
    link.click();
    window.URL.revokeObjectURL(url);
  };

  return (
    <Stack spacing={3} sx={{ p: 4 }}>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={12} md={4}>
          <DatePicker
            label="Start Date"
            value={startDate}
            onChange={setStartDate}
            slotProps={{ textField: { fullWidth: true } }}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <DatePicker
            label="End Date"
            value={endDate}
            onChange={setEndDate}
            slotProps={{ textField: { fullWidth: true } }}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <LoadingButton
            variant="contained"
            loading={loading}
            onClick={handleRefresh}
            startIcon={<RefreshIcon />}
            fullWidth
          >
            Refresh Logs
          </LoadingButton>
        </Grid>
      </Grid>

      {error && (
        <Alert severity="error" sx={{ width: '100%' }}>
          {error}
        </Alert>
      )}

      <Card>
        <CardHeader
          title="Sign-in Logs"
          action={
            <LoadingButton
              variant="outlined"
              onClick={() => handleDownload('signin')}
              startIcon={<DownloadIcon />}
            >
              Download CSV
            </LoadingButton>
          }
        />
        <CardContent>
          <Box sx={{ height: 400, width: '100%' }}>
            <DataGrid
              rows={signInLogs}
              columns={signInColumns}
              initialState={{
                pagination: { paginationModel: { pageSize: 10 } }
              }}
              pageSizeOptions={[10, 25, 50]}
              disableRowSelectionOnClick
              density="compact"
            />
          </Box>
        </CardContent>
      </Card>

      <Card>
        <CardHeader
          title="Audit Logs"
          action={
            <LoadingButton
              variant="outlined"
              onClick={() => handleDownload('audit')}
              startIcon={<DownloadIcon />}
            >
              Download CSV
            </LoadingButton>
          }
        />
        <CardContent>
          <Box sx={{ height: 400, width: '100%' }}>
            <DataGrid
              rows={auditLogs}
              columns={auditColumns}
              initialState={{
                pagination: { paginationModel: { pageSize: 10 } }
              }}
              pageSizeOptions={[10, 25, 50]}
              disableRowSelectionOnClick
              density="compact"
            />
          </Box>
        </CardContent>
      </Card>
    </Stack>
  );
}