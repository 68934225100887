import { useCallback } from 'react';
import { BackendAuthService } from './auth/backend-auth';
import { BackendApiClient } from './auth/backend-client';
import { BACKEND_AUTH_CONFIG } from './config/auth-config';
import { ApiService } from './services/api-service';

const JWT_SECRET = 'd21071fb0cc369dd2079df9e5889e6e63a1944c438157860c5f77b653dd2170287f85dc047bc0a60eab4b21e75798d5a2eb1bb193dfec47a8d066ffbe22e639b48afd0cad9b90c85055aceffa9a8ab23b87c8638212065c66a8ec87ce63a7f48f829e5173fed1ad0d2df02aeea9bd381aa84d7ce56bf51d50e6a94e4efa04b4206eb98af615f12ddaafd35744efa2666b5f01d83379ea85e7f45d0d7ecfb65dc921b3d6bf08aeb583fe3a31d5cf8c47a477aac1a38151afb79988f303a11a56be961d942f4f4e1f45c82fba021c1990408dae19eba24f6dae5fc06c9745a67019652550a4c0febfdf3b2b77383e4d3d3afe0c50ebccea82c55770559141c0ffd';

const backendAuth = new BackendAuthService(JWT_SECRET || '');
const backendClient = new BackendApiClient(
  process.env.API_BASE_URL || '',
  backendAuth,
  BACKEND_AUTH_CONFIG
);
const apiService = new ApiService(backendClient);

export const useApi = () => {
  const getLogs = useCallback(async (startDate: Date, endDate: Date) => {
    return apiService.getLogs(startDate, endDate);
  }, []);

  const getSecurityIncidents = useCallback(async () => {
    return apiService.getSecurityIncidents();
  }, []);

  const getIdentityData = useCallback(async () => {
    return apiService.getIdentityData();
  }, []);

  return {
    getLogs,
    getSecurityIncidents,
    getIdentityData
  };
};