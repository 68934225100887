
import { BackendApiClient } from '../auth/backend-client';
export class ApiService {
    private client: BackendApiClient;
  
    constructor(backendClient: BackendApiClient) {
      this.client = backendClient;
    }
  
    async getLogs(startDate: Date, endDate: Date) {
      const response = await this.client.fetchWithAuth(
        `/auditLogs/signIns?$filter=${startDate.toISOString()}&end=${endDate.toISOString()}`
      );
      return response.json();
    }
  
    async getSecurityIncidents() {
      const response = await this.client.fetchWithAuth('/api/incidents');
      return response.json();
    }
  
    async getIdentityData() {
      const response = await this.client.fetchWithAuth('/api/identity');
      return response.json();
    }
  }
