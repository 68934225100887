import React, { createContext, useContext, useState, useEffect, ReactNode } from 'react';
import { PublicClientApplication, AccountInfo, AuthenticationResult } from '@azure/msal-browser';
import { InteractiveBrowserCredential } from "@azure/identity";


export interface AuthState {
  account: AccountInfo | null;
  isAuthenticated: boolean;
  error: string | null;
  loading: boolean;
}

export interface AuthContextType extends AuthState {
  login: () => Promise<void>;
  logout: () => Promise<void>;
  getAccessToken: (scope: string) => Promise<string>;
}


const AUTH_CONFIG = {
  clientId: "019bb47d-104f-413d-94a6-84948a4eb5b3",
  authority: `https://login.microsoftonline.com/common`,
  redirectUri: `${window.location.origin}`,
};

const SCOPES = [
  "User.Read",
  "AuditLog.Read.All",
  "DeviceManagementConfiguration.Read.All",
  "IdentityRiskEvent.Read.All",
  "Policy.Read.All",
  "Reports.Read.All",
  "SecurityAlert.Read.All",
  "SecurityIncident.Read.All",
  "User.Read.All",
  "Directory.Read.All",
  "SecurityEvents.Read.All",
  "SecurityActions.Read.All",
];

export const msalInstance = new PublicClientApplication({
  auth: AUTH_CONFIG,
  cache: {
    cacheLocation: "sessionStorage",
    storeAuthStateInCookie: false,
  },
});

const credential = new InteractiveBrowserCredential(AUTH_CONFIG);

const AuthContext = createContext<AuthContextType | null>(null);

export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [authState, setAuthState] = useState<AuthState>({
    account: null,
    isAuthenticated: false,
    error: null,
    loading: false,
  });

  useEffect(() => {
    const accounts = msalInstance.getAllAccounts();
    if (accounts.length > 0) {
      setAuthState(prev => ({
        ...prev,
        account: accounts[0],
        isAuthenticated: true,
      }));
    }
  }, []);

  const login = async (): Promise<void> => {
    try {
      const result = await msalInstance.loginPopup({ scopes: SCOPES });
      setAuthState({
        account: result.account,
        isAuthenticated: true,
        error: null,
        loading: false,
      });
    } catch (error) {
      setAuthState(prev => ({
        ...prev,
        error: error instanceof Error ? error.message : 'Login failed',
        isAuthenticated: false,
      }));
      throw error;
    }
  };

  const logout = async (): Promise<void> => {
    try {
      await msalInstance.logoutPopup({
        account: authState.account ?? undefined,
      });
      setAuthState({
        account: null,
        isAuthenticated: false,
        error: null,
        loading: false,
      });
    } catch (error) {
      setAuthState(prev => ({
        ...prev,
        error: error instanceof Error ? error.message : 'Logout failed',
      }));
      throw error;
    }
  };

  const getAccessToken = async (scope: string): Promise<string> => {
    try {
      const tokenResponse = await credential.getToken(scope);
      if (!tokenResponse?.token) {
        throw new Error('No token received');
      }
      return tokenResponse.token;
    } catch (error) {
      setAuthState(prev => ({
        ...prev,
        error: error instanceof Error ? error.message : 'Failed to get token',
      }));
      throw error;
    }
  };

  return (
    <AuthContext.Provider value={{
      ...authState,
      login,
      logout,
      getAccessToken,
    }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};