import React from 'react';
import { Box, Card, CardContent, Typography, Button } from '@mui/material';
import { useAuth } from './authConfig';


export default function HomePage() {
  const { account, login, logout, loading, error } =  useAuth();

  if (loading) {
    return (
      <Box 
        display="flex" 
        justifyContent="center" 
        alignItems="center" 
        minHeight="100vh"
      >
        <Typography variant="h5">Loading...</Typography>
      </Box>
    );
  }
  
  if (error) {
    return (
      <Box 
        display="flex" 
        justifyContent="center" 
        alignItems="center" 
        minHeight="100vh"
      >
        <Typography color="error" variant="h6">Error: {error}</Typography>
      </Box>
    );
  }
 
  return (
    <Box 
      display="flex" 
      justifyContent="center" 
      alignItems="center" 
      minHeight="100vh"
      sx={{ background: 'linear-gradient(45deg, #1976d2 30%, #21CBF3 90%)' }}
    >
      <Card sx={{ maxWidth: 400, width: '100%', m: 2 }}>
        <CardContent sx={{ textAlign: 'center' }}>
          <Typography variant="h4" component="h1" gutterBottom>
            Azure Security Center
          </Typography>
          
          <Typography variant="body1" sx={{ mb: 3 }}>
            Monitor and manage your Azure and Microsoft 365 security in one place
          </Typography>

          {!account && (
            <Button 
              variant="contained" 
              size="large"
              onClick={login}
              sx={{ mt: 2 }}
            >
              Sign in with Microsoft
            </Button>
          )}

          {account && (
            <>
              <Typography variant="h6" sx={{ mt: 3 }}>
                Welcome, {account.name}!
              </Typography>
              <Button 
                variant="outlined" 
                size="large"
                onClick={logout}
                sx={{ mt: 2 }}
              >
                Sign Out
              </Button>
            </>
          )}
        </CardContent>
      </Card>
    </Box>
  );
}
