import React, { useState, useCallback, memo } from 'react';
import { useSecurityIncidents } from './useAzureLogs';
import {
  Card, CardContent, Grid, Typography, Box,
  Chip, IconButton, Dialog, DialogTitle, DialogContent, 
  DialogActions, Button, LinearProgress, Alert
} from '@mui/material';
import {
  Security, Warning, Error, Info,
  Assessment, Visibility, Timeline as TimelineIcon
} from '@mui/icons-material';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineDot from '@mui/lab/TimelineDot';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import  { SecurityIncident, SecurityEvent } from './index';

interface IncidentTimelineProps {
  incident: SecurityIncident;
}

interface IncidentDetailsProps {
  incident: SecurityIncident | null;
  onClose: () => void;
}

interface IncidentCardProps {
  incident: SecurityIncident;
  onIncidentClick: (incident: SecurityIncident) => void;
}

const IncidentTimeline = memo(({ incident }: IncidentTimelineProps) => {
  const getIconByType = (type: string) => {
    switch (type.toLowerCase()) {
      case 'detection': return <Security />;
      case 'alert': return <Warning />;
      case 'investigation': return <Visibility />;
      case 'remediation': return <Assessment />;
      default: return <Info />;
    }
  };

  return (
    <Timeline>
      {incident.events.map((event: SecurityEvent, index: number) => (
        <TimelineItem key={index}>
          <TimelineOppositeContent color="text.secondary">
            {new Date(event.timestamp).toLocaleString()}
          </TimelineOppositeContent>
          <TimelineSeparator>
            <TimelineDot color={
              event.severity === 'high' ? 'error' :
              event.severity === 'medium' ? 'warning' : 'info'
            }>
              {getIconByType(event.type)}
            </TimelineDot>
            {index < incident.events.length - 1 && <TimelineConnector />}
          </TimelineSeparator>
          <TimelineContent>
            <Typography variant="h6" component="span">
              {event.title}
            </Typography>
            <Typography>{event.description}</Typography>
            {event.entities && (
              <Box sx={{ mt: 1 }}>
                {event.entities.map((entity, i) => (
                  <Chip
                    key={i}
                    label={entity}
                    size="small"
                    sx={{ mr: 1, mb: 1 }}
                  />
                ))}
              </Box>
            )}
          </TimelineContent>
        </TimelineItem>
      ))}
    </Timeline>
  );
});

const IncidentDetails = memo(({ incident, onClose }: IncidentDetailsProps) => {
  if (!incident) return null;

  return (
    <Dialog open={Boolean(incident)} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>
        Incident Details - {incident.title}
        <Chip
          label={incident.status}
          color={
            incident.status === 'Active' ? 'error' :
            incident.status === 'Investigating' ? 'warning' : 'success'
          }
          size="small"
          sx={{ ml: 1 }}
        />
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Typography variant="subtitle2">Category</Typography>
            <Typography>{incident.category}</Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="subtitle2">Severity</Typography>
            <Typography>{incident.severity}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="subtitle2">Description</Typography>
            <Typography>{incident.description}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h6" gutterBottom>
              Incident Timeline
            </Typography>
            <IncidentTimeline incident={incident} />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
});

const IncidentCard = memo(({ incident, onIncidentClick }: IncidentCardProps) => (
  <Grid item xs={12}>
    <Card>
      <CardContent>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="h6" component="div">
            {incident.title}
            <Chip
              label={incident.severity}
              color={
                incident.severity === 'high' ? 'error' :
                incident.severity === 'medium' ? 'warning' : 'info'
              }
              size="small"
              sx={{ ml: 1 }}
            />
            <Chip
              label={incident.status}
              color={
                incident.status === 'Active' ? 'error' :
                incident.status === 'Investigating' ? 'warning' : 'success'
              }
              size="small"
              sx={{ ml: 1 }}
            />
          </Typography>
          <IconButton onClick={() => onIncidentClick(incident)}>
            <TimelineIcon />
          </IconButton>
        </Box>
        <Typography color="text.secondary">
          Category: {incident.category} - Last updated: {new Date(incident.lastUpdateDateTime).toLocaleString()}
        </Typography>
      </CardContent>
    </Card>
  </Grid>
));

export default function SecurityIncidents() {
  const { incidents, loading, error } = useSecurityIncidents();
  const [selectedIncident, setSelectedIncident] = useState<SecurityIncident | null>(null);

  const handleIncidentClick = useCallback((incident: SecurityIncident) => {
    setSelectedIncident(incident);
  }, []);

  const handleCloseDialog = useCallback(() => {
    setSelectedIncident(null);
  }, []);
  
  return (
    <Grid container spacing={3}>
      {loading && (
        <Grid item xs={12}>
          <LinearProgress />
        </Grid>
      )}

      {error && (
        <Grid item xs={12}>
          <Alert severity="error">{error}</Alert>
        </Grid>
      )}

      {incidents.map((incident: SecurityIncident) => (
        <IncidentCard
          key={incident.id}
          incident={incident}
          onIncidentClick={handleIncidentClick}
        />
      ))}

      <IncidentDetails
        incident={selectedIncident}
        onClose={handleCloseDialog}
      />
    </Grid>
  );
}